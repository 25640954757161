import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import CustomBreadCrumb from 'components/Common/Breadcrumb'
import HuisOpgeven from 'components/Diensten/HuisOpgeven'
import ProtoContent from 'components/Diensten/ProtoContent'

// Third Party
import styled from 'styled-components'

const StyledDienst = styled.div``

const Dienst = ({ data: { page }, pageContext }) => (
  <Layout>
    {/* {() => ( */}
      <>
        <SEO seo={page.seo} />
        <StyledDienst>
          <CustomBreadCrumb
            data={page}
            className="py-2"
            pageContext={pageContext}
          />
          <div className="container">
            <div className="row py-5">
              <div className="col-12 col-lg-7 pl-lg-3">
                <ProtoContent
                  content={page.recapDienst.contentservice}
                  image={page.recapDienst.image}
                  title={page.title}
                />
              </div>
              <div className="col-12 col-lg-5 pt-5 pt-lg-0 pr-lg-3">
                <HuisOpgeven className="pb-5 pt-4 pt-lg-0 justify-content-center justify-content-lg-end" />
              </div>
            </div>
          </div>
          {/* <CTABanner data={page} /> */}
        </StyledDienst>
      </>
    {/* )} */}
  </Layout>
)

export const pageQuery = graphql`
  query ($databaseId: Int!) {
    page: wpPage(databaseId: { eq: $databaseId }) {
      title
      databaseId
      recapDienst {
        contentservice
        image {
          localFile {
            publicURL
          }
        }
        banner {
          image {
            localFile {
              publicURL
            }
          }
          content
          buttonBanner {
            title
            url
            target
          }
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

export default Dienst
